import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "#F06F02",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12 0H0V12H12V0ZM1.33203 6L4.66406 9.33301L10.6641 3.33301L9.72656 2.38672L4.66406 7.44629L2.27344 5.05957L1.33203 6Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }